window.REDES.front =
  init: ->
    console.log 'REDES - FRONT'
    REDES.front.setArea()
  network:
    data: {}
    setup: ->
      nodes = new (vis.DataSet)(REDES.front.network.data.nodes)
      # create an array with edges
      edges = new (vis.DataSet)(REDES.front.network.data.edges)
      # create a network
      container = document.getElementById('mynetwork')
      data =
        nodes: nodes
        edges: edges
      options = {}
      network = new (vis.Network)(container, data, options)
  removeAreaFeedBack: (class_selector = '.area-feedback') ->
    $(document).ready ->
      $(class_selector).remove()
      return
    return
  defineArea: (value) ->
    if typeof Storage != 'undefined'
      localStorage.setItem('front.area', value)
      REDES.misc.setCookie('_area_code', value, 2147483647)
    else
      Swal.fire("Oops...", REDES.misc.i18n.t('global.your_browser_do_not_support_local_storage'), "error")
    return
  setArea: ->
    if typeof Storage != 'undefined'
      area = localStorage.getItem('front.area') || 0
      REDES.misc.setCookie('_area_code', area, 2147483647)
    else
      Swal.fire("Oops...", REDES.misc.i18n.t('global.your_browser_do_not_support_local_storage'), "error")
    return
