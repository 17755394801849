// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "channels"

Rails.start()
Turbolinks.start()

require("@nathanvda/cocoon")

// import '../src/javascripts/service_worker'
import 'bootstrap'
import "../src/stylesheets/front"
import "icheck-bootstrap"
import 'selectize'
import 'moment'
import '../src/javascripts/template/menu.min'
import 'magnific-popup'
import '../../../node_modules/magnific-popup/dist/magnific-popup.css'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin'
// import '@splidejs/splide'
// import Splide from '@splidejs/splide';
import '../../../node_modules/@splidejs/splide/dist/css/splide.min.css';
import 'particles.js'
// import 'vis-network'

// flatpickr do not depend of jQuery but you can use it as a plugin if you want
import flatpickr from 'flatpickr'
import { Spanish } from "flatpickr/dist/l10n/es.js"
flatpickr.localize(Spanish);
import '../../../node_modules/flatpickr/dist/flatpickr.min.css';

// REDES logic
function importAll (r) {
  r.keys().forEach(r);
}
import "../src/javascripts/main"
import '../src/javascripts/template/main'
import "../src/javascripts/front/main"
import "../src/javascripts/social-share-button"
importAll(require.context('../src/javascripts/front/modules/', true, /\.coffee$/))
