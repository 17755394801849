window.REDES =
  _template: {}
  $: $
  data: {}
  init: ->
    $('[data-toggle="tooltip"]').tooltip()
    # initializes selectize, all elements with the class selectize in the aux-form scope
    REDES.misc.selectizeByScope('body')
    REDES.misc.flatpickrByScope('body')
    console.log "%cMade with love from 🇨🇴 by Analisistem", "background-color: #FFFFFF; color: #1123EF"
    console.log "🌍 Visit us! https://analisistem.com 🌍"
    console.log '%cWhat are you doing here stranger?', "background-color: #1123EF; color: #FFFFFF"
    console.log 'We can see you...'
    return
  misc:
    disableVotes: (scope, data_attribute = '[data-target]') ->
      selector_classes = ['text-danger', 'text-success', 'vote-action']
      document.querySelectorAll(data_attribute).forEach (node) ->
        storage_ids = REDES.misc.getVoteIds()
        return if storage_ids == null
        id = node.getAttribute('data-target')
        if storage_ids.includes(id)
          node.querySelectorAll('.vote-action').forEach (link) ->
            link.classList.remove(...selector_classes)
            link.classList.add('text-secondary')
            link.removeAttribute('data-method')
            link.removeAttribute('href')
            return
        return
      return
    storeIdAfterVote: (scope) ->
      $("#{scope} .vote-action").on 'click', ->
        REDES.misc.setVoteIdInLocalStorage(this.id)
        return
      return
    getVoteIds: ->
      localStorage.getItem 'redes.votes'
    setVoteIdInLocalStorage: (id) ->
      ids = REDES.misc.getVoteIds()
      unless ids == null
        ids = JSON.parse(ids) 
        ids.push(id)
      else
        ids = [id]
        
      localStorage.setItem 'redes.votes', JSON.stringify(ids)
      return
    hiddenOverFlowForCustomInputs: (scope) ->
      $("#{scope} .custom-file")[0].style.overflow = 'hidden'
      return
    selectizeAfterInsertCocoon: (scope) ->
      $(scope).on 'cocoon:after-insert', (e, insertedItem, originalEvent) ->
        REDES.misc.selectizeByScope(this)
        return
      return
    changeValueBeforeChooseFile: (scope)->
      $("#{scope} .custom-file-input").on 'change', ->
        fileName = $(this).val().split('\\').pop()
        $(this).siblings("#{scope} .custom-file-label").addClass('selected').html fileName
        REDES.misc.hiddenOverFlowForCustomInputs(scope)
        return
      return
    # a random code taking into account the time and a 3 digit random code
    getRandomCode: ()->
      "#{new Date().getTime()}#{Math.floor((Math.random() * 1000) + 1);}"
    # flatpickr by scope
    # @param { String } [scope] selector
    flatpickrByScope: (scope)->
      $(scope).find('.flatpickr-date').not('flatpickred').each (index)->
        flatpickr $(this)[0], 
          enableTime: true
          dateFormat: "Y-m-d H:i"
        $(this).addClass 'flatpickred'
        return
      return
    # selectize by scope
    # @param { String } [scope] selector
    selectizeByScope: (scope)->
      $(scope).find('select.selectize').not('selectized').each (index)->
        $(this).selectize
          sortField: 'text'
        $(this).addClass 'selectized'
        return
      return
    # manage non-signed cookies by using the document.cookie object
    # @param { String } [cname] cookie name
    # @param { String } [cvalue] cookie value
    # @param { exdays } [exdays] expiration time in days
    setCookie: (cname, cvalue, exdays) ->
      d = new Date
      d.setTime d.getTime() + exdays * 24 * 60 * 60 * 1000
      expires = 'expires=' + d.toUTCString()
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
      return
    # manage non-signed cookies by using the document.cookie object, it fetch the
    # cookie the the name `cname`
    # @param { String } [cname] cookie name
    getCookie: (cname) ->
      name = cname + '='
      decodedCookie = decodeURIComponent(document.cookie)
      ca = decodedCookie.split(';')
      i = 0
      while i < ca.length
        c = ca[i]
        while c.charAt(0) == ' '
          c = c.substring(1)
        if c.indexOf(name) == 0
          return c.substring(name.length, c.length)
        i++
      ''
    i18n:
      t: (key)->
        lang = $("body").data("lang")
        _i18n = this[lang]
        keys = key.split(".")
        $.each keys, (i, val)->
          _i18n = _i18n[val]
        _i18n
      es:
        global:
          your_browser_do_not_support_local_storage: 'Su explorador no soporta Local Storage, por favor contacte a soporte'
      en:
        global:
          your_browser_do_not_support_local_storage: 'Your browser do not support local storage, please contact support'
  # clears the REDES.data information
  clearData: ->
    REDES.data = {}
    console.log 'DATA cleared'
$(document).on 'turbolinks:load', ->
  REDES.init()
