window.REDES.front.object =
  show:
    setup: ->
      attachmentImage = $('.front-content-object.page--show .attachment')
      return if attachmentImage[0] == undefined
      
      url = "/content/objects/photoswipe?id=#{attachmentImage.attr('data-id')}"

      getItems = (callback) ->
        $.ajax
          url: url
          method: 'GET'
          success: (object) ->
            items = [
              {
                src: object.url
                w: object.width
                h: object.height
              }
            ]
            callback(items)
            return
      
      getItems (items) ->
        pswpElement = document.querySelectorAll('.pswp')[0]
        # define options (if needed)
        options = 
          index: 0
          escKey: false
          closeOnScroll: false

        # Initializes and opens PhotoSwipe
        gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options)
        attachmentImage.click ->
          gallery.init()
          return
        return
      return
