window.REDES.front.pages =
  index:
    testimonialsSplide: null
    brandsSplide: null
    micrositesSplide: null
    announcementLaunched: false
    ignoreAnnouncement: false
    setup: ->
      $(document).scroll ->
        currentTop = $(this).scrollTop()
        if !REDES.front.pages.index.ignoreAnnouncement && !REDES.front.pages.index.announcementLaunched && currentTop > 1200
          REDES.front.pages.index.announcementLaunched = true
          $('#announcementModal').modal('show')
      $('a.go-to-element').click (e) ->
        e.preventDefault
        REDES.front.pages.index.ignoreAnnouncement = true
        REDES.front.pages.index.goToElement($(this).data('target'), $(this).data('modal'))
        return false
      @testimonialsSplide = new Splide('section.testimonials .splide', {
        type    : 'loop',
        perPage : 3,
        perMove : 1,
        breakpoints:
          992 :
            perPage: 2
          510 :
            perPage: 1
      })
      @brandsSplide = new Splide('section.brands .splide', {
        type    : 'loop',
        perPage : 8,
        perMove : 1,
        breakpoints:
          992 :
            perPage: 2
          510 :
            perPage: 1
      })
      @micrositesSplide = new Splide('section.microsites .splide', {
        type    : 'loop',
        perPage : 6,
        perMove : 1,
        breakpoints:
          1824 :
            perPage: 5
          1520 :
            perPage: 4
          1216 :
            perPage: 3
          912 :
            perPage: 2
          608 :
            perPage: 1
      })
      @testimonialsSplide.mount()
      @brandsSplide.mount()
      @micrositesSplide.mount()
      particlesJS 'networkEffect',
        'particles':
          'number':
            'value': 60
            'density':
              'enable': true
              'value_area': 800
          'color': 'value': '#D9EBFD'
          'shape':
            'type': 'circle'
            'stroke':
              'width': 3
              'color': '#FFFFFF'
            'polygon':
              'nb_sides': 6
          'opacity':
            'value': 1
            'random': false
            'anim':
              'enable': true
              'speed': 1
              'opacity_min': 0.5
              'sync': false
          'size':
            'value': 10
            'random': true
            'anim':
              'enable': true
              'speed': 10
              'size_min': 0.2
              'sync': false
          'line_linked':
            'enable': true
            'distance': 200
            'color': '#D9EBFD'
            'opacity': 1
            'width': 1.5
          'move':
            'enable': true
            'speed': 4
            'direction': 'none'
            'random': false
            'straight': false
            'out_mode': 'out'
            'attract':
              'enable': false
              'rotateX': 600
              'rotateY': 1200
        'interactivity':
          'detect_on': 'canvas'
          'events':
            'onhover':
              'enable': false
              'mode': 'repulse'
            'onclick':
              'enable': false
              'mode': 'push'
            'resize': false
          'modes':
            'grab':
              'distance': 400
              'line_linked': 'opacity': 1
            'bubble':
              'distance': 400
              'size': 40
              'duration': 2
              'opacity': 8
              'speed': 3
            'repulse': 'distance': 200
            'push': 'particles_nb': 4
            'remove': 'particles_nb': 2
        'retina_detect': true
        'config_demo':
          'hide_card': false
          'background_color': '#D9EBFD'
          'background_image': ''
          'background_position': '50% 50%'
          'background_repeat': 'no-repeat'
          'background_size': 'cover'
      return
    goToElement: (id, modal) ->
      if modal
        $(modal).modal('hide')
      scrollTop = $(id)[0].offsetTop
      $('html,body').animate { scrollTop: scrollTop }, 1500
      setTimeout (->
        REDES.front.pages.index.ignoreAnnouncement = false
        ),
        2000
      return