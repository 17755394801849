"use strict";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*---------------------------------------------
Template name :  Socibook
Version       :  1.0
Author        :  ThemeLooks
Author url    :  http://themelooks.com

NOTE:
------
Please DO NOT EDIT THIS JS, you may need to use "custom.js" file for writing your custom js.
We may release future updates so it will overwrite this file. it's better and safer to use "custom.js".

[Table of Content]

    01: Main Menu
    02: Sticky Nav
    03: Background Image
    04: Check Data
    05: Changing svg color
    06: Google map
    07: Back to top button
    08: Offcanvas Triggar
    09: Search Active Class
    10: Popup Video
    11: Countdown Timer
    12: Countdown Timer
    13: Feature Active Class
    14: Elevate Zoom Active
    15: Increase/Decrease Product Quantity
    16: Chose Us Active Class
    17: Add & Remove Love

----------------------------------------------*/
window.REDES._template = {
  setup: function() {
    (function ($) {
      "use strict";
      /*===================
      01: Main Menu
      =====================*/

      $('ul.nav li a[href="#"]').on('click', function (event) {
        event.preventDefault();
      });
      /* Menu Maker */

      $(".nav-wrapper").menumaker({
        title: '<span></span>',
        format: "multitoggle"
      });
      $($(window)).on('scroll', function () {
        if (!$('ul.nav').hasClass('open')) {
          $('#menu-button').removeClass('menu-opened');
        }

        ;
      });

      function mobileMenu() {
        if ($(window).width() < 992) {
          $(".nav-wrapper").find("ul.nav").show();
          $(".menu-wrap").hide();
        } else {
          $(".menu-wrap").show();
        }
      } // $(window).on("resize", () => mobileMenu());


      $(window).on("resize", function () {
        mobileMenu();
      });
      mobileMenu();
      /* Menu Responsive in Small Device */

      function subMenu() {
        var $subMain = $('.menu-wrap .nav > li > ul');
        var $subSub = $('.menu-wrap .nav > li > ul ul');
        $subMain.each(function () {
          if ($(window).width() > 991) {
            if ($(this).offset().left + $(this).width() > $(window).width()) {
              $(this).css({
                'left': 'auto',
                'right': '0'
              });
            }
          }
        });
        $subSub.each(function () {
          if ($(window).width() > 991) {
            if ($(this).offset().left + $(this).width() > $(window).width()) {
              $(this).css({
                'left': 'auto',
                'right': '100%'
              });
            }
          }
        });
      }

      subMenu();
      $(window).resize(subMenu);
      /*========================
      02: Sticky Nav
      ==========================*/

      $(window).on("scroll", function () {
        var scroll = $(window).scrollTop();

        if (scroll < 180) {
          $(".header-main.love-sticky").removeClass("sticky fixed-top fadeInDown animated");
        } else {
          $(".header-main.love-sticky").addClass("sticky fixed-top fadeInDown animated");
        }
      });
      /*========================
      03: Background Image
      ==========================*/

      var $bgImg = $('[data-bg-img]');
      $bgImg.css('background-image', function () {
        return 'url("' + $(this).data('bg-img') + '")';
      }).removeAttr('data-bg-img').addClass('bg-img');
      /*==================================
      04: Check Data
      ====================================*/

      var checkData = function checkData(data, value) {
        return typeof data === 'undefined' ? value : data;
      };
      /*==================================
      05: Changing svg color
      ====================================*/


      jQuery('img.svg').each(function () {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');
        jQuery.get(imgURL, function (data) {
          // Get the SVG tag, ignore the rest
          var $svg = jQuery(data).find('svg'); // Add replaced image's ID to the new SVG

          if (typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
          } // Add replaced image's classes to the new SVG


          if (typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass + ' replaced-svg');
          } // Remove any invalid XML tags as per http://validator.w3.org


          $svg = $svg.removeAttr('xmlns:a'); // Check if the viewport is set, else we gonna set it if we can.

          if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
            $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
          } // Replace image with new SVG


          $img.replaceWith($svg);
        }, 'xml');
      });
      /*==================================
      06: Google map
      ====================================*/

      var style = [{
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [{
          "color": "#C7E5FD"
        }, {
          "lightness": 17
        }]
      }, {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [{
          "color": "#f5f5f5"
        }, {
          "lightness": 20
        }]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#CECECE"
        }, {
          "lightness": 17
        }]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [{
          "color": "#CECECE"
        }, {
          "lightness": 29
        }, {
          "weight": 0.2
        }]
      }, {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [{
          "color": "#ffffff"
        }, {
          "lightness": 18
        }]
      }, {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [{
          "color": "#ffffff"
        }, {
          "lightness": 16
        }]
      }, {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{
          "color": "#f5f5f5"
        }, {
          "lightness": 21
        }]
      }, {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [{
          "color": "#dedede"
        }, {
          "lightness": 21
        }]
      }, {
        "elementType": "labels.text.stroke",
        "stylers": [{
          "visibility": "on"
        }, {
          "color": "#ffffff"
        }, {
          "lightness": 16
        }]
      }, {
        "elementType": "labels.text.fill",
        "stylers": [{
          "saturation": 36
        }, {
          "color": "#333333"
        }, {
          "lightness": 40
        }]
      }, {
        "elementType": "labels.icon",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [{
          "color": "#f2f2f2"
        }, {
          "lightness": 19
        }]
      }, {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#fefefe"
        }, {
          "lightness": 20
        }]
      }, {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [{
          "color": "#fefefe"
        }, {
          "lightness": 17
        }, {
          "weight": 1.2
        }]
      }];
      var $map = $('[data-trigger="map"]'),
          $mapOps;

      if ($map.length) {
        // Map Options
        $mapOps = $map.data('map-options'); // Map Initialization

        window.initMap = function () {
          $map.css('min-height', '600px');
          $map.each(function () {
            var $t = $(this),
                map,
                lat,
                lng,
                zoom;
            $mapOps = $t.data('map-options');
            lat = parseFloat($mapOps.latitude, 10);
            lng = parseFloat($mapOps.longitude, 10);
            zoom = parseFloat($mapOps.zoom, 10);
            map = new google.maps.Map($t[0], {
              center: {
                lat: lat,
                lng: lng
              },
              zoom: zoom,
              scrollwheel: false,
              disableDefaultUI: true,
              zoomControl: true,
              styles: style
            });
            map = new google.maps.Marker({
              position: {
                lat: lat,
                lng: lng
              },
              map: map,
              animation: google.maps.Animation.DROP,
              draggable: false,
              icon: 'assets/img/map-marker.png'
            });
          });
        };

        initMap();
      }

      ;
      /*============================================
      07: Back to top button
      ==============================================*/

      var $backToTopBtn = $('.back-to-top');

      if ($backToTopBtn.length) {
        var scrollTrigger = 400,
            // px
        backToTop = function backToTop() {
          var scrollTop = $(window).scrollTop();

          if (scrollTop > scrollTrigger) {
            $backToTopBtn.addClass('show');
          } else {
            $backToTopBtn.removeClass('show');
          }
        };

        backToTop();
        $(window).on('scroll', function () {
          backToTop();
        });
        $backToTopBtn.on('click', function (e) {
          e.preventDefault();
          $('html,body').animate({
            scrollTop: 0
          }, 700);
        });
      }
      /*==================================
      08: Offcanvas Triggar
      ====================================*/


      $('.offcanvas-trigger').on('click', function () {
        $('.offcanvas-wrapper, .offcanvas-overlay').addClass('show');
      });
      $('.offcanvas-overlay, .offcanvas-close').on('click', function () {
        $('.offcanvas-wrapper, .offcanvas-overlay').removeClass('show');
      });
      /*==================================
      09: Search Active Class
      ====================================*/

      $('.header.style--two').find('.header-search').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).addClass('active');
      });
      $(document).on('click', 'body', function (e) {
        $('.header.style--two .header-search.active').removeClass('active');
      });
      /*==================================
      10: Popup Video
      ====================================*/

      $(".video-btn").magnificPopup({
        type: "video"
      });
      $(".audio-btn").magnificPopup({
        type: "audio"
      });
      $(".image-link").magnificPopup({
        type: "image"
      });
      $(".gallery-link").magnificPopup({
        gallery: {
          enabled: true
        },
        type: "image"
      });
      /*==================================
      11: Countdown Timer
      ====================================*/

      if ($('#countdown').length) {
        $('#countdown').countdown({
          date: '08/16/2021 23:59:59'
        });
      }
      /*==================================
      12: Countdown Timer
      ====================================*/


      var tabSelect = $('[data-tab-select]');
      var tab = $('[data-tab]');
      tabSelect.each(function () {
        var tabText = $(this).data('tab-select');
        $(this).on('click', function () {
          $(this).addClass('active').siblings().removeClass('active');
          tab.each(function () {
            if (tabText === $(this).data('tab')) {
              $(this).fadeIn(500).siblings().hide(); // for click
              // $(this).fadeIn(500).siblings().stop().hide(); // active if hover

              $(this).addClass('active').siblings().removeClass('active');
            }
          });
        });

        if ($(this).hasClass('active')) {
          tab.each(function () {
            if (tabText === $(this).data('tab')) {
              $(this).addClass('active');
            }

            if ($(this).hasClass('active')) {
              $(this).show().siblings().hide();
            }
          });
        }
      });
      /*==================================
      13: Feature Active Class
      ====================================*/

      $('.single-feature.activate').addClass('active');
      $('.feature.layer').on('mouseleave', function () {
        $('.single-feature').removeClass('active');
        $('.single-feature.activate').addClass('active');
      });
      $('.single-feature').on('mouseenter', function () {
        $('.single-feature').removeClass('active');
        $(this).addClass('active');
      });
      /*==================================
      14: Elevate Zoom Active
      ====================================*/

      if ($('#elevate-zoom').length) {
        //initiate the plugin and pass the id of the div containing gallery images
        $("#img_01").elevateZoom(_defineProperty({
          gallery: 'gal1',
          cursor: 'pointer',
          galleryActiveClass: 'active',
          imageCrossfade: true,
          loadingIcon: 'http://www.elevateweb.co.uk/spinner.gif',
          zoomType: "inner"
        }, "cursor", "crosshair")); //pass the images to Fancybox

        $("#img_01").bind("click", function (e) {
          var ez = $('#img_01').data('elevateZoom');
          $.fancybox(ez.getGalleryList());
          return false;
        });
      }
      /*==================================
      15: Increase/Decrease Product Quantity
      ====================================*/

      /* 15.1: Increase */


      $('.plus').on('click', function () {
        var $qty = $(this).parent().find('input');
        var currentVal = parseInt($qty.val());

        if (!isNaN(currentVal)) {
          $qty.val(currentVal + 1);
        }
      });
      /* 15.2: Decrease */

      $('.minus').on('click', function () {
        var $qty = $(this).parent().find('input');
        var currentVal = parseInt($qty.val());

        if (!isNaN(currentVal) && currentVal > 1) {
          $qty.val(currentVal - 1);
        }
      });
      /*==================================
      16: Chose Us Active Class
      ====================================*/

      $('.single-choose').on('mouseover', function () {
        $('.single-choose').removeClass('active');
        $(this).addClass('active');
      });
      /*==================================
      17: Add & Remove Love
      ====================================*/

      $('.post-footer .loved').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
      });
    })(jQuery);
  }
}
