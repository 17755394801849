window.REDES.front.office =
  form:
    setup: ->
      $('select.cities-selectize').selectize()
      $('select.states-selectize').selectize
        onChange: (value) ->
          return unless value
          $.ajax
            type: 'GET'
            url: "/location/cities?state_id=#{value}"
            success: (data) ->
              citiesSelectize = $('select.cities-selectize')[0].selectize
              citiesSelectize.clear()
              citiesSelectize.clearOptions()
              citiesSelectize.open()
            
              $.each data, (i, city) ->
                citiesSelectize.addOption
                  value: city._id.$oid
                  text: city.name
                return

              citiesSelectize.refreshOptions
              return