window.REDES.front.microsites =
  show:
    blogSplide: null
    packagesSplide: null
    eventsSplide: null
    setup: ->
      @blogSplide = new Splide('section.blog .splide', {
        type    : 'loop',
        perPage : 3,
        perMove : 1,
        breakpoints:
          992 :
            perPage: 2
          510 :
            perPage: 1
      })
      @eventsSplide = new Splide('section.events .splide', {
        type    : 'loop',
        perPage : 3,
        perMove : 1,
        breakpoints:
          992 :
            perPage: 2
          510 :
            perPage: 1
      })
      @packagesSplide = new Splide('section.indexed-contents .splide', {
        type    : 'loop',
        perPage : 3,
        perMove : 1,
        breakpoints:
          992 :
            perPage: 2
          510 :
            perPage: 1
      })
      @packagesSplide.mount()
      @eventsSplide.mount()
      @blogSplide.mount()
